<template>
  <SharePageTemplate>
    <template v-slot:image-content>
      <v-img
        :src="$route.query.link"
        controls
        style="width: 100%; height: 100%; border-radius: 5px !important"
      />
    </template>
  </SharePageTemplate>
</template>

<script>
import SharePageTemplate from '@/components/share/SharePageTemplate.vue';

export default {
  name: 'SharedImageView',
  components: {SharePageTemplate},
};
</script>
